@import '~/src/styles/use-bootstrap.global';
@import '~/src/styles/colors.global';
@import '~/src/styles/typography.scss';

.container {
  background-color: $color-panel-light;
  composes: global(border-top);
  composes: global(border-bottom);
  composes: global(pt-5);
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  p {
    @extend .bodyLargeCompact;
  }
}
