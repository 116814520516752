@import '~font-awesome/css/font-awesome.css';

@import './vendor/bootstrap.global';

html,
input {
  text-rendering: optimizeLegibility;
  -webkit-font-variant-ligatures: common-ligatures;
  font-variant-ligatures: common-ligatures;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-kerning: normal;
}
