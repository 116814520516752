@import '~/src/styles/use-bootstrap.global';
@import '~/src/styles/colors.global';
@import '~/src/styles/typography';

.container {
  composes: global(my-5);
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  p {
    @extend .bodyLargeCompact;
    margin-bottom: 0;
  }
}

.inputContainer {
  composes: global(mt-4);
}

.emailInput {
  border-top-left-radius: 500px;
  border-bottom-left-radius: 500px;
  composes: global(px-3);
}
