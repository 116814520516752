/* atlas-tb */

/* colour definitions */

$color-light-green: #86c9b9;
$color-dark-green: #3b9da7;
$color-cyan: #0d7da0;
$color-green: #0d561e;

/* colour uses */

$color-tint-primary: $color-cyan;
$color-tint-secondary: $color-cyan;
