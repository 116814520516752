@import '~/src/styles/use-bootstrap.global';
@import '~/src/styles/colors.global';
@import '~/src/styles/typography.scss';

.container {
  composes: global(my-5);
}

.content {
  h1,
  h2,
  p {
    margin-bottom: 0;
    + p {
      margin-top: 24/16 * 1rem;
    }
  }
  p {
    @extend .bodyLarge;
    + h1,
    + h2 {
      margin-top: 48/16 * 1rem;
    }
  }
}
