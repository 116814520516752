// variables to generate customised bootstrap

@import '~/src/styles/colors.global';

// override default bootstrap and common variables

$grid-gutter-width: 40px;

$font-family-base: 'Minion-Regular', serif;
$font-size-base: 19/16 * 1rem;
$body-color: $color-text;

$headings-font-weight: normal;
$headings-color: $color-tint-primary;

$input-bg: $color-panel-light;
$input-border-color: $gray-300;
$input-placeholder-color: $color-placeholder-text;

$btn-border-radius: 500px;
$btn-border-radius-lg: 500px;
$btn-border-radius-sm: 500px;

$btn-padding-x: 1.2rem;
$btn-padding-y: 0.5rem;
$btn-padding-x-lg: 1.2rem;
$btn-padding-y-lg: 0.5rem;
$btn-padding-x-sm: 1rem;
$btn-padding-y-sm: 0.3rem;

$link-hover-decoration: none;
$link-hover-color: none;

$transition-base: all 0.1s ease-in-out;
$btn-transition: opacity 0.1s ease-in-out, color 0.1s ease-in-out;

$dropdown-link-color: $color-grey-dark;
$dropdown-link-hover-color: rgba($color-grey-dark, 0.7);
$dropdown-item-padding-x: 1rem;
$dropdown-item-padding-y: 0.5rem;
$dropdown-spacer: 0;
$dropdown-padding-y: 0;

$nav-divider-margin-y: 0;

$spacer: 1rem;
$spacers: (
  4h: (
    $spacer * 2,
  ),
);

$table-hover-bg: $color-panel !default;
$table-head-bg: $color-panel !default;

$theme-colors: (
  'mid': $gray-600,
  'muted': $gray-400,
  'white': $color-white,
);

$headings-font-weight: 700 !default;

$transition-base: all 0.2s ease-in-out !default;
$transition-fade: opacity 0.15s linear !default;
$transition-collapse: height 0.35s ease !default;

$input-color: $gray-700;
$input-group-addon-color: $gray-500;
$input-group-addon-bg: $color-panel-light;
$input-border-color: $gray-300;
