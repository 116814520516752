@import '~/src/styles/use-bootstrap.global';
@import '~/src/styles/colors.global';
@import '~/src/styles/typography.scss';

.container {
  position: relative;
  background-color: $color-grey-panel;
  composes: global(py-5);
  box-shadow: 0 50vh 0 50vh $color-grey-panel;
}

.content {
  text-align: center;
}

.linkContainer {
  &:before {
    content: ' · ';
  }
  a {
    color: $body-color;
  }
}

.logos {
  composes: global(pt-5);
  composes: global(flex-column);
  composes: global(flex-sm-row);
  display: flex;
  align-items: center;
  justify-content: center;
}

.logoContainer {
  composes: global(mb-5);
  composes: global(mx-sm-4);
  composes: global(mb-sm-0);
}
